@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';
@use 'mixins/input';

.box-s {
  box-shadow: 0 0 3px rgb(0 0 0 / 0.1) !important;
}

.form-check-flex {
  align-items: center;
  display: flex;

  > * {
    cursor: inherit;

    &:first-child {
      margin-right: 10px;
    }
  }

  .form-check-input {
    margin-top: 0;
  }
}

.form-check-input {
  &:not(.form-check-custom) {
    &:checked {
      background-color: hsl(var(--std-color-4));
      border-color: hsl(var(--std-color-4));
    }

    &:focus {
      box-shadow: 0 0 4px 0 hsla(var(--std-color-4), 0.5);
    }

    &:not(:checked) {
      border-color: variables.$gray-silver-sand;
    }
  }
}

.form-range {
  &.std-skin {
    $form-range-thumb-border-radius: 1.25rem;
    $form-range-track-bg: #4d4d4d;
    $form-range-thumb-focus-box-shadow: 0 0 0 1px lighten($form-range-track-bg, 37%);
    $form-range-thumb-focus-box-shadow-width: 0.25rem;
    $form-range-thumb-height: 1.25rem;
    $form-range-track-height: 0.1875rem;
  
    border: none;
    height: calc($form-range-thumb-height + $form-range-thumb-focus-box-shadow-width * 2);
  
    &:focus {
      &::-webkit-slider-thumb { box-shadow: $form-range-thumb-focus-box-shadow; }
      &::-moz-range-thumb     { box-shadow: $form-range-thumb-focus-box-shadow; }
    }
  
    &::-moz-range-track, &::-webkit-slider-runnable-track {
      background-color: $form-range-track-bg;
      height: $form-range-track-height;
    }
  
    &::-moz-range-thumb {
      background-color: $form-range-track-bg;
      border-radius: $form-range-thumb-border-radius;
      height: $form-range-thumb-height;
      width: $form-range-thumb-height;
    }
  
    &::-webkit-slider-thumb {
      background-color: $form-range-track-bg;
      border-radius: $form-range-thumb-border-radius;
      height: $form-range-thumb-height;
      margin-top: ($form-range-track-height - $form-range-thumb-height) * .5; // Webkit specific
      width: $form-range-thumb-height;
    }
  }
}

.form-switch {
  .form-check-input {
    &:not(.form-check-custom) {
      &:checked {
        background-color: hsl(var(--std-toggle-active-bg));
        border-color: hsl(var(--std-toggle-active-bg));
      }

      &:focus {
        box-shadow: 0 0 4px 0 hsla(var(--std-toggle-active-bg), 0.5);

        &:not(:checked) {
          --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
        }
      }
    }
  }
}

.m-t-1 {
  margin-top: -1px;
}

.ng-select.bt-show ::ng-deep .ng-select-container {
  min-height: 0px !important;
  height: 25px;
}

.input-icon {
  @include input.get-h-input-res();

  --icon-size: #{variables.$default-font-size};
  position: relative;

  @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
    --icon-size: #{calc(variables.$default-font-size * 1.5)};
  }

  > .icon-prepend {
    color: variables.$gray-pantone;
    height: var(--icon-size);
    left: calc(variables.$default-font-size * 0.75);
    position: absolute;
    top: calc((var(--input-height) - var(--icon-size)) / 2);
    width: var(--icon-size);
  }

  > .icon-times {
    position: absolute;
    top: calc((100% - 14px) / 2);
    right: calc(variables.$default-font-size * 0.75);
  }

  > input {
    color: variables.$gray-pantone;
    height: var(--input-height);
    padding-left: calc(variables.$default-font-size * 1.25 + var(--icon-size));

    @supports selector(:has(*)) {
      &:has(~ .icon-times) {
        padding-right: calc(variables.$default-font-size * 0.5 + var(--icon-size));
      }
    }

    @supports not selector(:has(*)) {
      padding-right: calc(variables.$default-font-size * 0.5 + var(--icon-size));
    }
  }
}

.is-invalid {
  border-color: red !important;
}

.normal-input-form {
  height: 36px;
  border-radius: 4px !important;
  border: 1px solid #a4a4a4 !important;
  background-color: #ffffff !important;
}

input::placeholder {
  color: variables.$gray-neutrals;
}

input[type='checkbox'] {
  &.checkbox-default {
    $checkmark-bg-color: hsl(var(--std-color-secondary));

    @extend .default-size;

    -webkit-appearance: none;
    -webkit-border-radius: 6px;
    appearance: none;
    border-radius: 6px;
    padding: 9px;
    border-color: variables.$gray-silver-sand;

    &:checked {
      background-image: var(--std-checkmark-url);
      border: none;
      background-color: $checkmark-bg-color;
      color: var(--std-btn-text-primary);
    }

    &:indeterminate {
      background-image: var(--std-checkmark-indeterminate-url);
      border: none;
      background-color: $checkmark-bg-color;
    }

    &:disabled {
      background-color: variables.$gray-silver-sand;
      opacity: 1;
    }

    &:active {
      border-color: $checkmark-bg-color;
    }
  }

  &.default-size {
    height: 20px;
    width: 20px;
  }
}

input {
  &[type='number'] {
    &.hide-arrow {
      /* Firefox */
      -moz-appearance: textfield;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
