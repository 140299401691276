@use 'sass:map';

// TODO: Need implement
/** @deprecate by name -- The old set color which referred yet. */
$bright-red: rgb(252, 49, 28);
$royal-blue: #003453;
$yale-blue: #2d5b8f;
$snow-flurry: #eaffc9;
// The new set color. The most of these refer by https://html-color.codes/
$black-deep: #1e2234;
$black-light: #2a3049;
$black-neutrals: #1d1f2c;
$black-pantone: #12141f;
$blue: #03a9f3;
$blue-ball-blue: #29adce;
$blue-bell-light: #b8cbf1;
$blue-bright-cerulean: #15afd5;
$blue-maya: #73c2fb;
$blue-sapphire: #1750c0;
$blue-tang: #1958d3;
$blue-united-nations: #658fe2;
$blue-yale: #2d5b8f;
$dark-gray: #4d4d4d;
$darkgreen: #2e7054;
$gray: #a4a4a4;
$gray-anti-flash: #eaeaed;
$gray-gainsboro: #dcdcdc;
$gray-light: #f9f9fc;
$gray-neutrals: #707485;
$gray-pantone: #55596d;
$gray-roman-silver: #9da0ab;
$gray-silver-sand: #ced4da;
$gray-silver-sand-mid: #bdbfc7;
$gray-sonic: #757575;
$green: #28a745;
$green-magic-mint-light: #d4ffe7;
$green-mint-cream: #f2faf8;
$green-munsell: #00a877;
$green-shamrock: #009f6b;
$green-ufo: #3cd070;
$green-vru: #2d7054;
$imperial-gold: rgb(205, 174, 124);
$orange: #f05a27;
$purple-medium-lavender: #b054de;
$red: #df3131;
$red-light-carmine-pink: #e46a76;
$red-pig-blood: #f53636;
$red-radical-red: #ff2d55;
$red-scarlet: #ff2400;
$red-strong: #e84b41;
$warning: #ffc107;
$white-light: #f8f8f8;
$white-pure: #ffffff;
$white-smoke: #f0f1f3;
$yellow-selective: #ffb854;

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  // breakpoint between screen 1280px and 1400px
  xl: 1320px,
  // breakpoint between screen 1920px and 2048px
  xxl: 1984px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1220px,
  xxl: 1784px
);

$spacers: (
  u12: 1.75,
  u11: 1.6875,
  u10: 1.625,
  u9: 1.5625,
  u8: 1.5,
  u7: 1.4375,
  u6: 1.375,
  u5: 1.3125,
  u4: 1.25,
  u3: 1.1875,
  u2: 1.125,
  u1: 1.0625,
  u0: 1,
  '0': 0,
  l0: 1,
  l1: 0.9375,
  l2: 0.875,
  l3: 0.8125,
  l4: 0.75,
  l5: 0.6875,
  l6: 0.625,
  l7: 0.5625,
  l8: 0.5,
  l9: 0.4375,
  l10: 0.375,
  l11: 0.3125,
  l12: 0.25
) !default;

$border-radius: 12px;
$border-radius-input: 8px;
$border-table-color: $gray-anti-flash;
$height-input-default: 44px;
$height-input-default-sm: 37px;

// Add the standard height of element at here
$element-height: (
  'dropdown': 36px,
  'button': 36px
);
$navbar-min-height: 5rem;

// typography
$default-font-size: 16px;
$default-tablet-font-size: 13px;
$default-mobile-font-size: 13px;
$font-size-upper: (
  1: 1.0625rem,
  2: 1.125rem,
  3: 1.1875rem,
  4: 1.25rem,
  5: 1.3125rem,
  6: 1.375rem,
  7: 1.4375rem
);

$font-size-lower: (
  1: 0.9375rem,
  2: 0.875rem,
  3: 0.8125rem,
  4: 0.75rem,
  5: 0.6875rem,
  6: 0.625rem,
  7: 0.5625rem
);

$font-weights: (
  lighter: light,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  bolder: bolder
);

// Group
$std-color: () !default;
$std-color: map-merge(
  (
    'black-deep': $black-deep,
    'black-light': $black-light,
    'black-neutrals': $black-neutrals,
    'black-pantone': $black-pantone,
    'blue': $blue,
    'blue-ball-blue': $blue-ball-blue,
    'blue-bell-light': $blue-bell-light,
    'blue-bright-cerulean': $blue-bright-cerulean,
    'blue-maya': $blue-maya,
    'blue-sapphire': $blue-sapphire,
    'blue-tang': $blue-tang,
    'blue-united-nations': $blue-united-nations,
    'blue-yale': $blue-yale,
    'dark-gray': $dark-gray,
    'darkgreen': $darkgreen,
    'gray': $gray,
    'gray-anti-flash': $gray-anti-flash,
    'gray-gainsboro': $gray-gainsboro,
    'gray-light': $gray-light,
    'gray-neutrals': $gray-neutrals,
    'gray-pantone': $gray-pantone,
    'gray-roman-silver': $gray-roman-silver,
    'gray-silver-sand': $gray-silver-sand,
    'gray-silver-sand-mid': $gray-silver-sand-mid,
    'gray-sonic': $gray-sonic,
    'green': $green,
    'green-magic-mint-light': $green-magic-mint-light,
    'green-mint-cream': $green-mint-cream,
    'green-munsell': $green-munsell,
    'green-shamrock': $green-shamrock,
    'green-ufo': $green-ufo,
    'green-vru': $green-vru,
    'imperial-gold': $imperial-gold,
    'orange': $orange,
    'purple-medium-lavender': $purple-medium-lavender,
    'red': $red,
    'red-light-carmine-pink': $red-light-carmine-pink,
    'red-pig-blood': $red-pig-blood,
    'red-scarlet': $red-scarlet,
    'red-strong': $red-strong,
    'snow-flurry': $snow-flurry,
    'warning': $warning,
    'white-light': $white-light,
    'white-pure': $white-pure,
    'white-smoke': $white-smoke,
    'yellow-selective': $yellow-selective
  ),
  $std-color
);

$spacers-ref-base: $spacers;
$spacers-rem: $spacers;

// Convert each spacer to a constant value in px by multiplying it by the default font size.
@each $name, $spacer in $spacers {
  $spacers-ref-base: map.set($spacers-ref-base, $name, $spacer * $default-font-size);
  $spacers-rem: map.set($spacers-rem, $name, #{$spacer}rem);
  $spacers-rem: map.remove($spacers-rem, 0);
}

$utilities: (
  'height': (
    responsive: true,
    property: height,
    class: h,
    values: (
      0: 0,
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  ),
  'margin': (
    responsive: true,
    property: margin,
    class: m,
    values: $spacers-rem
  ),
  'margin-x': (
    responsive: true,
    property: margin-right margin-left,
    class: mx,
    values: $spacers-rem
  ),
  'margin-y': (
    responsive: true,
    property: margin-top margin-bottom,
    class: my,
    values: $spacers-rem
  ),
  'margin-bottom': (
    responsive: true,
    property: margin-bottom,
    class: mb,
    values: $spacers-rem
  ),
  'margin-end': (
    responsive: true,
    property: margin-right,
    class: me,
    values: $spacers-rem
  ),
  'margin-start': (
    responsive: true,
    property: margin-left,
    class: ms,
    values: $spacers-rem
  ),
  'margin-top': (
    responsive: true,
    property: margin-top,
    class: mt,
    values: $spacers-rem
  ),
  'padding': (
    responsive: true,
    property: padding,
    class: p,
    values: $spacers-rem
  ),
  'padding-x': (
    responsive: true,
    property: padding-right padding-left,
    class: px,
    values: $spacers-rem
  ),
  'padding-y': (
    responsive: true,
    property: padding-top padding-bottom,
    class: py,
    values: $spacers-rem
  ),
  'padding-top': (
    responsive: true,
    property: padding-top,
    class: pt,
    values: $spacers-rem
  ),
  'padding-end': (
    responsive: true,
    property: padding-right,
    class: pe,
    values: $spacers-rem
  ),
  'padding-bottom': (
    responsive: true,
    property: padding-bottom,
    class: pb,
    values: $spacers-rem
  ),
  'padding-start': (
    responsive: true,
    property: padding-left,
    class: ps,
    values: $spacers-rem
  ),
  'margin-base': (
    responsive: true,
    property: margin,
    class: m-base,
    values: $spacers-ref-base
  ),
  'margin-x-base': (
    responsive: true,
    property: margin-right margin-left,
    class: mx-base,
    values: $spacers-ref-base
  ),
  'margin-y-base': (
    responsive: true,
    property: margin-top margin-bottom,
    class: my-base,
    values: $spacers-ref-base
  ),
  'margin-bottom-base': (
    responsive: true,
    property: margin-bottom,
    class: mb-base,
    values: $spacers-ref-base
  ),
  'margin-end-base': (
    responsive: true,
    property: margin-right,
    class: me-base,
    values: $spacers-ref-base
  ),
  'margin-start-base': (
    responsive: true,
    property: margin-left,
    class: ms-base,
    values: $spacers-ref-base
  ),
  'margin-top-base': (
    responsive: true,
    property: margin-top,
    class: mt-base,
    values: $spacers-ref-base
  ),
  'padding-base': (
    responsive: true,
    property: padding,
    class: p-base,
    values: $spacers-ref-base
  ),
  'padding-x-base': (
    responsive: true,
    property: padding-right padding-left,
    class: px-base,
    values: $spacers-ref-base
  ),
  'padding-y-base': (
    responsive: true,
    property: padding-top padding-bottom,
    class: py-base,
    values: $spacers-ref-base
  ),
  'padding-top-base': (
    responsive: true,
    property: padding-top,
    class: pt-base,
    values: $spacers-ref-base
  ),
  'padding-end-base': (
    responsive: true,
    property: padding-right,
    class: pe-base,
    values: $spacers-ref-base
  ),
  'padding-bottom-base': (
    responsive: true,
    property: padding-bottom,
    class: pb-base,
    values: $spacers-ref-base
  ),
  'padding-start-base': (
    responsive: true,
    property: padding-left,
    class: ps-base,
    values: $spacers-ref-base
  ),
  'width': (
    responsive: true,
    property: width,
    class: w,
    values: (
      0: 0,
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  )
);
