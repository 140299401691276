@use 'variables';

.std-accordion {
  &.accordion {
    --bs-accordion-active-color: variables.$black-neutrals;
    --bs-accordion-body-padding-x: 0.75rem;
    --bs-accordion-body-padding-y: 0.5rem;
    --bs-accordion-btn-color: variables.$black-neutrals;
    --bs-accordion-btn-icon: url('data:image/svg+xml, %3csvg id=%22chevron-down%22 width=%2224%22 height=%2224%22 viewBox=%220 0 24 24%22 fill=%22none%22 stroke=%22%2355596D%22 stroke-width=%22inherit%22 xmlns=%22http://www.w3.org/2000/svg%22%3e%3cpath d=%22M6 9L12 15L18 9%22 stroke=%22inherit%22 stroke-width=%22inherit%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22 /%3e%3c/svg%3e');
    --bs-accordion-btn-icon-width: 1.5rem;
    --bs-accordion-btn-active-icon: var(--bs-accordion-btn-icon);
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-btn-padding-x: 0.625rem;
    --bs-accordion-btn-padding-y: 0.625rem;
    --bs-border-radius: 8px;
    --bs-border-width: 0px;

    .accordion-button {
      background-color: hsl(var(--std-color-1));
      border-radius: var(--bs-accordion-inner-border-radius);

      &:not(.collapsed) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .accordion-body {
      background-color: variables.$gray-light;
      border-bottom-left-radius: var(--bs-border-radius);
      border-bottom-right-radius: var(--bs-border-radius);

      .container-approver {
        background-color: variables.$white-pure;
      }
    }

    .accordion-item {
      border-radius: var(--bs-border-radius);
      margin-bottom: 1rem;
    }
  }
}
