@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';
@use 'mixin';
@use 'mixins/input';

$enable-important-utilities: true !default;
@import 'bootstrap/scss/mixins/utilities';

@each $name, $height in variables.$element-height {
  .eh-#{$name} {
    height: $height;
  }
}

@each $name, $color in variables.$std-color {
  .bg-#{$name} {
    background-color: $color;
  }

  .text-#{$name} {
    color: $color;
  }
}

.bg-std-select-active {
  background-color: hsl(var(--std-select-active-bg));
}

.text-std-select-active {
  color: hsl(var(--std-select-active-text));
}

// Code from node_modules/bootstrap/scss/utilities/_api.scss
@each $key, $breakpoint in map-keys(variables.$grid-breakpoints) {
  @include breakpoints.media-breakpoint-up($key, variables.$grid-breakpoints) {
    $infix: breakpoints.breakpoint-infix($key, variables.$grid-breakpoints);

    @each $key, $utility in variables.$utilities {
      @if type-of($utility) == 'map' and (map-get($utility, responsive) or $infix == '') {
        @include generate-utility($utility, $infix);
      }
    }

    @each $key, $value in variables.$spacers {
      .g#{$infix}-#{$key},
      .gx#{$infix}-#{$key} {
        --bs-gutter-x: #{$value}rem;
      }

      .g#{$infix}-#{$key},
      .gy#{$infix}-#{$key} {
        --bs-gutter-y: #{$value}rem;
      }

      .g#{$infix}-base-#{$key},
      .gx#{$infix}-base-#{$key} {
        --bs-gutter-x: #{calc($value * variables.$default-font-size)};
      }

      .g#{$infix}-base-#{$key},
      .gy#{$infix}-base-#{$key} {
        --bs-gutter-y: #{calc($value * variables.$default-font-size)};
      }
    }
  }
}

.flex-fill-0 {
  flex: 1 1 0;
}

.h-0 {
  height: 0;
}

.h-input {
  height: variables.$height-input-default;
}

.h-input-res {
  @include input.h-input-res();
}

.flex-basis-0 {
  flex-basis: 0;
}

.flex-grow-auto {
  flex: 1 0 auto;
}

.lh-normal {
  line-height: normal;
}

.no-resize {
  resize: none;
}

.pointer {
  cursor: pointer;
}

.round-button {
  border-radius: variables.$border-radius;

  &.btn {
    --bs-border-radius: #{variables.$border-radius};
  }
}

.round-input {
  border-radius: variables.$border-radius-input;
}

.scrollbar-invisible {
  @include mixin.disable-scrollbar;
}

.w-min {
  width: min-content;
}

@mixin background-blur-std {
  background-color: #2A3049CC;
  backdrop-filter: blur(1px);
}

.background-blur {
  @include background-blur-std;
  z-index: 99;
}

.background-blur-logout {
  @include background-blur-std;
  z-index: 1050 !important;
}