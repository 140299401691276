@use 'sass:math';
@use 'variables';
@use 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/buttons';

@mixin btn-circle($size: variables.$height-input-default) {
  align-items: center;
  border-radius: calc($size / 2);
  display: flex;
  height: $size;
  justify-content: center;
  padding: 0;
  width: $size;
}

@mixin btn-std($color, $disabled, $hover, $active) {
  @include button-variant(
    $background: $color,
    $border: $color,
    $hover-background: $hover,
    $hover-border: $hover,
    $active-background: $active,
    $active-border: $active,
    $disabled-background: $disabled,
    $disabled-border: $disabled,
    $disabled-color: #bdbfc7
  );

  padding: 0.75rem 1.25rem;
}

@mixin btn-std-pre-hsl($color) {
  $disabled: #eaeaed;
  $hover: adjust-color($color, $hue: 0, $saturation: 5%, $lightness: 3%);
  $active: adjust-color($color, $hue: 0, $saturation: 0%, $lightness: -18%);

  @include btn-std($color, $disabled, $hover, $active);
}

@mixin btn-std-2ry($color, $disabled, $background, $hover, $active) {
  $disabled: #eaeaed;
  $background: adjust-color($color, $hue: 0, $saturation: -8%, $lightness: 50%);
  $hover: adjust-color($color, $hue: 0, $saturation: -10%, $lightness: 40%);
  $active: adjust-color($color, $hue: 0, $saturation: -10%, $lightness: 20%);

  @include button-variant(
    $background: $background,
    $border: $background,
    $color: $color,
    $hover-background: $hover,
    $hover-border: $hover,
    $hover-color: $color,
    $active-background: $active,
    $active-border: $active,
    $active-color: if(lightness($color) > 50%, $color, white),
    $disabled-background: $disabled,
    $disabled-border: $disabled,
    $disabled-color: #bdbfc7
  );

  padding: 0.75rem 1.25rem;
}

@mixin btn-std-2ry-pre-hsl($color) {
  $disabled: #eaeaed;
  $background: adjust-color($color, $hue: 0, $saturation: -8%, $lightness: 50%);
  $hover: adjust-color($color, $hue: 0, $saturation: -10%, $lightness: 40%);
  $active: adjust-color($color, $hue: 0, $saturation: -10%, $lightness: 20%);

  @include btn-std-2ry($color, $disabled, $background, $hover, $active);
}

@mixin btn-std-outline(
  $color,
  $active-background: white,
  $active-border,
  $border: variables.$gray-anti-flash,
  $font-color: variables.$gray-neutrals,
  $hover-background,
  $disabled: #eaeaed
) {
  $hover-border: $disabled;
  $hover-color: $font-color;

  @include button-outline-variant(
    $color: $font-color,
    $color-hover: $hover-color,
    $active-background: $active-background,
    $active-border: $active-border,
    $active-color: $color
  );

  --#{$prefix}btn-bg: white;
  --#{$prefix}btn-border-color: #{$border};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  --#{$prefix}btn-disabled-color: #bdbfc7;
  --#{$prefix}btn-disabled-bg: #{$disabled};
  --#{$prefix}btn-disabled-border-color: #{$disabled};

  padding: 0.75rem 1.25rem;
}

@mixin btn-std-outline-pre-hsl(
  $color,
  $font-color: variables.$gray-neutrals,
  $border: variables.$gray-anti-flash,
  $disabled: #eaeaed
) {
  $hover-background: change-color(
    $color: adjust-color($font-color, $saturation: 18%, $lightness: 25%),
    $alpha: 0.05
  );
  $active-background: white;
  $active-border: adjust-color($color, $saturation: 0%, $lightness: 20%);

  @include btn-std-outline(
    $color: $font-color,
    $active-background: white,
    $active-border: $active-border,
    $border: $border,
    $disabled: $disabled,
    $font-color: $font-color,
    $hover-background: $hover-background
  );
}

@mixin get-btn-h-res(
  $sm: variables.$height-input-default-sm,
  $md: variables.$height-input-default-sm,
  $lg: variables.$height-input-default
) {
  --btn-height: #{$sm};

  @include breakpoints.media-breakpoint-between('sm', 'lg', variables.$grid-breakpoints) {
    --btn-height: #{$md};
  }

  @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
    --btn-height: #{$lg};
  }
}

@mixin get-btn-h-sm-res {
  --btn-height: #{math.div(variables.$height-input-default-sm, 1.321)};

  @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
    --btn-height: #{math.div(variables.$height-input-default, 1.375)};
  }
}

@mixin reset-button-style {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  /* show a hand cursor on hover; some argue that we
  should keep the default arrow cursor for buttons */
  cursor: pointer;
}

@mixin base-btn {
  @include reset-button-style;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 100%;
  border-radius: 5px;
  color: white;
  background: black;
  box-shadow: 0 3px 6px #00000029;
  transition: 200ms;

  &:hover {
    box-shadow: 0 3px 10px #00000060;
    transform: translateY(-2px);
  }
}
